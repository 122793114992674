import "./App.css";
import Routes from "./routing";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import reducer from "./reducers";
import { NotificationContainer } from 'react-notifications'
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistStore } from "redux-persist";

import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import { HelmetProvider } from 'react-helmet-async'

import 'react-notifications/lib/notifications.css'
import "react-datepicker/dist/react-datepicker.css";

registerLocale("ru", ru);


const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(thunk),
});

const persistor = persistStore(store);


function App() {
  return (
    <HelmetProvider>
      <PersistGate persistor={persistor}>
        <Provider store={store}>
          <Routes />
          <NotificationContainer />
        </Provider>
      </PersistGate>
    </HelmetProvider>
  );
}

export default App;
