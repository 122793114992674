import React from "react";
import styles from "./Loader.module.css";

export default function Loader({
  top = "",
  left = "",
  color = "grey",
  size = 80
}) {
  
  const ldsRingStyle = {
    width: `${size}px`,
    height: `${size}px`
  }

  const childDivStyle = {
    borderWidth: `${0.1*size}px`,
    borderStyle: 'solid',
    borderColor: `${color} transparent transparent transparent`,
    width: `${0.8*size}px`,
    height: `${0.8*size}px`,
    margin: `${0.1*size}px`,
  }
  
  return (
    <div className={styles.load_wrapper} style={{top : top, left : left}}>
      <div className={styles.lds_ring} style={ldsRingStyle}>
        <div style={childDivStyle}></div>
        <div style={childDivStyle}></div>
        <div style={childDivStyle}></div>
        <div style={childDivStyle}></div>
      </div>
    </div>
  );
}
