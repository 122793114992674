import * as types from "../actions/types";



const initialState = {
    loading: false,
    userInfo: {}
};


export default function auth(state = initialState, action) {
    switch (action.type) {
      case types.AUTH_COMPLETED:
        return {
          ...state,
          ...action.user,
        };
      case types.SIGNED_OUT:
        return {
          ...initialState
        };
      case types.SET_AUTH_USER_INFO:
        return {
          ...state,
          userInfo: {...state.userInfo, ...action.payload}
        };
      case types.SET_AUTH_LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      case types.SET_NEW_USER_PHOTO:
        return {
          ...state,
          userInfo: {...state.userInfo, profile_photo: action.payload}
        };
      default:
        return state;
    }
  }