import React, { useState } from 'react'
import styles from './ChooseLayout.module.css'
import { Layout1 } from './Layout1/Layout1'
import { Layout2 } from './Layout2/Layout2'
import { Layout3 } from './Layout3/Layout3'
import { Layout4 } from './Layout4/Layout4'
import { ChooseContext } from '../../../../utils/context'

export const ChooseLayout = ({ view, changeView }) => {
  
  const [layoutsVisible, setLayoutsVisible] = useState(false)

  return (
    <ChooseContext.Provider value={{view, changeView}}>
      <div className={styles.wrapper} onClick={() => setLayoutsVisible(prev => !prev)}>
        <div className={styles.squares}></div>
        <div className={styles.squares}></div>
        <div className={styles.squares}></div>
        <div className={styles.squares}></div>
        {layoutsVisible && (
          <div className={styles.select}>
            <Layout4/>
            <Layout1/>
            <Layout2/>
            <Layout3/>
          </div>
        )}
      </div>
    </ChooseContext.Provider>
  )
}
