import React, { useContext } from 'react'
import styles from './Layout1.module.css'
import { ChooseContext } from '../../../../../utils/context'
import classNames from 'classnames'

export const Layout1 = () => {
  
  const {view, changeView} = useContext(ChooseContext)

  const wrapClassNames = classNames(styles.wrapper, {[styles.selected]: view === 1})
  
  return (
    <div className={wrapClassNames} onClick={() => changeView(1)}>
      <div className={styles.topRow}>
        <div className={styles.mainUser}></div>
        <div className={styles.screen}></div>
        <div className={styles.mainUser}></div>
      </div>
      <div className={styles.bottomRow}>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
        <div className={styles.row}>
          <div className={styles.user}></div>
          <div className={styles.user}></div>
        </div>
      </div>
    </div>
  )
}
