import React, { useCallback, useEffect, useState } from 'react'
import { Popover } from '../Popover/Popover'
import { SwapUsersSelect } from './SwapUsersSelect/SwapUsersSelect'
import { User } from './User/User'
import styles from './View2.module.css'

export const View2 = ({ subscribers = [], publisher, handleFoolScreenVideoStream, toggleSubscribeAudio }) => {
  const [visibleUsers, setVisibleUsers] = useState([publisher, ...subscribers.slice(0, 18)])
  const [visiblePopover, setVisiblePopover] = useState(false)
  const [mainStream, setMainStream] = useState(publisher)

  const visiblePopoverFasle = useCallback(e => {
    if (e.key === 'Escape') {
      setVisiblePopover(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', visiblePopoverFasle)
    return () => {
      document.removeEventListener('keydown', visiblePopoverFasle)
    }
  }, [])

  useEffect(() => {
    if (subscribers.length === visibleUsers.length) {
      setVisibleUsers([...visibleUsers, ...subscribers.slice(visibleUsers.length - 1, 18)])
    } else {
      if (visibleUsers.find(e => e === mainStream)) {
        setMainStream(publisher)
      }
      setVisibleUsers([publisher, ...subscribers.slice(0, 18)])
    }
  }, [subscribers.length])

  const handleSwapUsers = user => {
    const newVisibleUsers = [...visibleUsers]
    newVisibleUsers[visiblePopover] = user
    setVisibleUsers(newVisibleUsers)
    setVisiblePopover(false)
  }

  const usersForSelect = [publisher, ...subscribers]/* .filter(user => !visibleUsers.some(e => e === user)) */

    const handleToMainStream = index => {
    setMainStream(visibleUsers[index])
  }


  return (
    <div className={styles.wrapper}>
      <div className={styles.topRow}>
        <div>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 11}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[11] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(11)}
                    streamManager={visibleUsers[11]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toMainStream={() => handleToMainStream(11)}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 12}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[12] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(12)}
                    streamManager={visibleUsers[12]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toMainStream={() => handleToMainStream(12)}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 13}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[13] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(13)}
                    streamManager={visibleUsers[13]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toMainStream={() => handleToMainStream(13)}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
        </div>
        <div>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 0}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[0] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(0)}
                    streamManager={visibleUsers[0]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                    toMainStream={() => handleToMainStream(0)}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 1}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[1] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(1)}
                    streamManager={visibleUsers[1]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toMainStream={() => handleToMainStream(1)}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 2}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[2] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(2)}
                    streamManager={visibleUsers[2]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toMainStream={() => handleToMainStream(2)}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
        </div>

        <div className={styles.mainUser}>
          {mainStream ? (
            <div>
              <User
                streamManager={mainStream}
                handleFoolScreenVideoStream={handleFoolScreenVideoStream}
              />
            </div>
          ) : null}
        </div>
        <div>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 3}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[3] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(3)}
                    streamManager={visibleUsers[3]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toMainStream={() => handleToMainStream(3)}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 4}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[4] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(4)}
                    streamManager={visibleUsers[4]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toMainStream={() => handleToMainStream(4)}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 5}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[5] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(5)}
                    streamManager={visibleUsers[5]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toMainStream={() => handleToMainStream(5)}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
        </div>
        <div>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 15}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[15] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(15)}
                    streamManager={visibleUsers[15]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toMainStream={() => handleToMainStream(15)}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 16}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[16] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(16)}
                    streamManager={visibleUsers[16]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toMainStream={() => handleToMainStream(16)}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
          <Popover
            onHide={() => setVisiblePopover(false)}
            visible={visiblePopover === 17}
            content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
          >
            <div className={styles.user}>
              {visibleUsers[17] ? (
                <div>
                  <User
                    openUsersList={() => usersForSelect.length && setVisiblePopover(17)}
                    streamManager={visibleUsers[17]}
                    handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                    toMainStream={() => handleToMainStream(17)}
                    toggleSubscribeAudio={toggleSubscribeAudio}
                  />
                </div>
              ) : null}
            </div>
          </Popover>
        </div>
      </div>
      <div className={styles.bottomRow}>
        <Popover
          onHide={() => setVisiblePopover(false)}
          visible={visiblePopover === 14}
          content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers}/>}
        >
          <div className={styles.user}>
            {visibleUsers[14] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(14)}
                  streamManager={visibleUsers[14]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toMainStream={() => handleToMainStream(14)}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover
          onHide={() => setVisiblePopover(false)}
          visible={visiblePopover === 6}
          content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
        >
          <div className={styles.user}>
            {visibleUsers[6] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(6)}
                  streamManager={visibleUsers[6]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toMainStream={() => handleToMainStream(6)}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover
          onHide={() => setVisiblePopover(false)}
          visible={visiblePopover === 7}
          content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
        >
          <div className={styles.user}>
            {visibleUsers[7] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(7)}
                  streamManager={visibleUsers[7]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toMainStream={() => handleToMainStream(7)}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover
          onHide={() => setVisiblePopover(false)}
          visible={visiblePopover === 8}
          content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
        >
          <div className={styles.user}>
            {visibleUsers[8] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(8)}
                  streamManager={visibleUsers[8]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toMainStream={() => handleToMainStream(8)}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover
          onHide={() => setVisiblePopover(false)}
          visible={visiblePopover === 9}
          content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
        >
          <div className={styles.user}>
            {visibleUsers[9] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(9)}
                  streamManager={visibleUsers[9]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toMainStream={() => handleToMainStream(9)}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover
          onHide={() => setVisiblePopover(false)}
          visible={visiblePopover === 10}
          content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
        >
          <div className={styles.user}>
            {visibleUsers[10] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(10)}
                  streamManager={visibleUsers[10]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toMainStream={() => handleToMainStream(10)}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
        <Popover
          onHide={() => setVisiblePopover(false)}
          visible={visiblePopover === 18}
          content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
        >
          <div className={styles.user}>
            {visibleUsers[18] ? (
              <div>
                <User
                  openUsersList={() => usersForSelect.length && setVisiblePopover(18)}
                  streamManager={visibleUsers[18]}
                  handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                  toMainStream={() => handleToMainStream(18)}
                  toggleSubscribeAudio={toggleSubscribeAudio}
                />
              </div>
            ) : null}
          </div>
        </Popover>
      </div>
    </div>
  )
}
