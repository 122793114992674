import React, {useState} from "react";
import styles from "./Auth.module.css";
import avatar from "../../img/default.png"
import AuthForm from "./AuthForm/AuthForm";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { auth } from '../../actions/auth';


export default function Auth() {
  
  const history = useHistory()
  const dispatch = useDispatch()
  const {token, loading} = useSelector(state => state.auth)
  
  const [path] = useState(history.location.state?.sessionPath || '/lk')

  const authAction = (values) => {
    dispatch(auth(values))
  }

  token && path && history.push(path)


  return (
    <div className={styles.content}>
          <div className={styles.avatar}>
              <img src={avatar} alt="о_О" />
          </div>
          <AuthForm
            loading={loading}
            submitForm={authAction}
          />
    </div>
  );
}
