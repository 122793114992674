import React, { Component } from "react";
import styles from "./UserVideoComponent.module.css";

export class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
  }

  componentDidMount() {
    if (this.props && !!this.videoRef && this.props.streamManager) {
      this.props.streamManager.addVideoElement && this.props.streamManager.addVideoElement(this.videoRef.current);
    }
  }

  componentDidUpdate(props) {
    if (props && !!this.videoRef && this.props.streamManager) {
      this.props.streamManager.addVideoElement && this.props.streamManager.addVideoElement(this.videoRef.current);
    }
  }

  render() {
    return (
      <div className={styles.videoComponent}>
        <video autoPlay={true} ref={this.videoRef} />
      </div>
    );
  }
}
