import React, { useCallback, useEffect, useState } from 'react'
import { Popover } from '../Popover/Popover'
import { SwapUsersSelect } from './SwapUsersSelect/SwapUsersSelect'
import { User } from './User/User'
import styles from './View5.module.css'

export const View5 = ({ subscribers = [], publisher, handleFoolScreenVideoStream, toggleSubscribeAudio }) => {
  const [visibleUsers, setVisibleUsers] = useState([publisher, ...subscribers.slice(0, 6)])
  const [visiblePopover, setVisiblePopover] = useState(false)
  const [mainStream, setMainStream] = useState(publisher)

  const visiblePopoverFasle = useCallback(e => {
    if (e.key === 'Escape') {
      setVisiblePopover(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', visiblePopoverFasle)
    return () => {
      document.removeEventListener('keydown', visiblePopoverFasle)
    }
  }, [])

  useEffect(() => {
    if (subscribers.length === visibleUsers.length) {
      setVisibleUsers([...visibleUsers, ...subscribers.slice(visibleUsers.length - 1, 6)])
    } else {
      if (visibleUsers.find(e => e === mainStream)) {
        setMainStream(publisher)
      }
      setVisibleUsers([publisher, ...subscribers.slice(0, 6)])
    }
  }, [subscribers.length])

  const handleSwapUsers = user => {
    const newVisibleUsers = [...visibleUsers]
    newVisibleUsers[visiblePopover] = user
    setVisibleUsers(newVisibleUsers)
    setVisiblePopover(false)
  }

  const usersForSelect = [publisher, ...subscribers]/* .filter(user => !visibleUsers.some(e => e === user)) */

  const handleToMainStream = index => {
    setMainStream(visibleUsers[index])
  }


  return (
    <div className={styles.wrapper}>
      <div className={styles.topRow}>
        <div className={styles.mainUser}>
          {mainStream ? (
            <div>
              <User streamManager={mainStream} handleFoolScreenVideoStream={handleFoolScreenVideoStream} />
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.bottomRow}>
        <div className={styles.user}>
          {visibleUsers[0] ? (
            <div>
              <User
                openUsersList={() => usersForSelect.length && setVisiblePopover(0)}
                streamManager={visibleUsers[0]}
                handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                toggleSubscribeAudio={toggleSubscribeAudio}
                toMainStream={() => handleToMainStream(0)}
              />
            </div>
          ) : null}
        </div>
        <div className={styles.user}>
          {visibleUsers[1] ? (
            <div>
              <User
                openUsersList={() => usersForSelect.length && setVisiblePopover(1)}
                streamManager={visibleUsers[1]}
                handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                toMainStream={() => handleToMainStream(1)}
                toggleSubscribeAudio={toggleSubscribeAudio}
              />
            </div>
          ) : null}
        </div>
        <div className={styles.user}>
          {visibleUsers[2] ? (
            <div>
              <User
                openUsersList={() => usersForSelect.length && setVisiblePopover(2)}
                streamManager={visibleUsers[2]}
                handleFoolScreenVideoStream={handleFoolScreenVideoStream}
                toMainStream={() => handleToMainStream(2)}
                toggleSubscribeAudio={toggleSubscribeAudio}
              />
            </div>
          ) : null}
        </div>
      <div className={styles.user}>
        {visibleUsers[3] ? (
          <div>
            <User
              openUsersList={() => usersForSelect.length && setVisiblePopover(3)}
              streamManager={visibleUsers[3]}
              handleFoolScreenVideoStream={handleFoolScreenVideoStream}
              toMainStream={() => handleToMainStream(3)}
              toggleSubscribeAudio={toggleSubscribeAudio}
            />
          </div>
        ) : null}
      </div>
      <div className={styles.user}>
        {visibleUsers[4] ? (
          <div>
            <User
              openUsersList={() => usersForSelect.length && setVisiblePopover(4)}
              streamManager={visibleUsers[4]}
              handleFoolScreenVideoStream={handleFoolScreenVideoStream}
              toMainStream={() => handleToMainStream(4)}
              toggleSubscribeAudio={toggleSubscribeAudio}
            />
          </div>
        ) : null}
      </div>
      <div className={styles.user}>
        {visibleUsers[5] ? (
          <div>
            <User
              openUsersList={() => usersForSelect.length && setVisiblePopover(5)}
              streamManager={visibleUsers[5]}
              handleFoolScreenVideoStream={handleFoolScreenVideoStream}
              toMainStream={() => handleToMainStream(5)}
              toggleSubscribeAudio={toggleSubscribeAudio}
            />
          </div>
        ) : null}
      </div>
      <div className={styles.user}>
        {visibleUsers[6] ? (
          <div>
            <User
              openUsersList={() => usersForSelect.length && setVisiblePopover(6)}
              streamManager={visibleUsers[6]}
              handleFoolScreenVideoStream={handleFoolScreenVideoStream}
              toMainStream={() => handleToMainStream(6)}
              toggleSubscribeAudio={toggleSubscribeAudio}
            />
          </div>
        ) : null}
      </div>
      <Popover
        visible={typeof visiblePopover === 'number'}
        content={<SwapUsersSelect users={usersForSelect} swapUsers={handleSwapUsers} />}
        onHide={() => setVisiblePopover(false)}
      >
        <div></div>
      </Popover>
      </div>
    </div>
  )
}
