import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getConferences,
  addConference,
  editConference,
  deleteConference,
  getConfUsers,
  sendSmsForConference
} from "../../../actions/lk";
import Loader from "../../../components/Loader/Loader";
import { IoMdAdd } from "react-icons/io";
import moment from 'moment'
import 'moment/locale/ru' 
import styles from "../LK.module.css";
import Modal from 'react-responsive-modal'
import DatePicker from "react-datepicker";
import { FiX } from "react-icons/fi";
import { TbBrandTelegram, TbCopy } from "react-icons/tb";
import { BsEnvelope } from "react-icons/bs";
import { MdModeEditOutline } from "react-icons/md";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import { getInitials } from "../../../utils/smallHelpers";
import { BACKEND_DOMAIN } from "../../../assets/const";
import { createNotification } from "../../../components/Notifications/Notifications";


moment.locale('ru');

export const Conferences = () => {
  
  const [currentConf, setCurrentConf] = useState(false)

  const { conferences, loading } = useSelector((state) => state.lk);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getConferences())
  }, []);

  const handleDeleteConf = (e, confId) => {
    e.stopPropagation()
    dispatch(deleteConference(confId))
  }
  
  const shareConf = (e, conf) => {
    e.stopPropagation()
    const link = document.createElement('a')
    const text = `Приглашаем Вас принять участие в конференции, которая состоится ${moment(conf.date).format('DD.MM.yyyy в hh:mm')}`
    link.setAttribute('href', `https://t.me/share/url?url=${conf.url}&text=${text}`)
    link.setAttribute('target', 'blank')
    link.click()
  }

  const openConference = (url) => {
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('target', 'blank')
    link.click()
  }

  const sendMessage = (e, confId) => {
    e.stopPropagation()
    dispatch(sendSmsForConference(confId))
  }

  const editConference = (e, conf) => {
    e.stopPropagation()
    setCurrentConf(conf)
  }
  
  return (
    <>
      <h1>Конференции</h1>
      {loading ? (
        <Loader top="52%" left="47%" />
      ) : (
        <>
          <div className={styles.confHeader}>
            <div>Название:</div>
            <div>Дата:</div>
            <div>Создано:</div>
            <div></div>
          </div>
          <div className={styles.confWrapper}>
              <ul>
                {conferences && conferences.map(conf =>
                  <li key={conf.url} onClick={() => openConference(conf.url)}>
                    <div>{conf.name}</div>
                    <div>{moment(conf.date).format('LLL')}</div>
                    <div>{moment(conf.created_at).format('DD.MM.YYYY')}</div>
                    <div className={styles.iconsBlock}>
                      {/* <BsEnvelope
                        onClick={(e) => sendMessage(e, conf.id)}
                        title='Отправить SMS участникам'
                      /> */}
                      <TbBrandTelegram
                        onClick={(e) => shareConf(e, conf)}
                        title='Поделиться в Telegram'
                      />
                      <MdModeEditOutline
                        onClick={(e) => editConference(e, conf)}
                        title='Редактировать'
                      />
                      <FiX
                        onClick={(e) => handleDeleteConf(e, conf.id)}
                        title='Удалить'
                      />
                    </div>
                  </li>
                )}
              </ul>
          </div>
          <button className={styles.addButton} onClick={() => setCurrentConf({})}>
            <IoMdAdd size={30}/>
            создать новую конференцию
          </button>
        </>
      )}
      <ConferenceModal
        shareConf={shareConf}
        data={currentConf}
        closeModal={() => setCurrentConf(null)}
      />
    </>
  );
};


const ConferenceModal = ({data, closeModal, shareConf}) => {

  const dispatch = useDispatch()
  const userId = useSelector((state) => state.auth.user_id);
  const { contacts, confUsers, loading } = useSelector(state => state.lk) 
  
  const [_data, setData] = useState({...data})
  const [_confUsers, setConfUsers] = useState([])
  const [smsSpam, setSmsSpam] = useState(false)
  const [tgSpam, setTgSpam] = useState(false)
  
  const addMode = data && !data.id
  const disabledButtons = !_data.url || _confUsers.length < 2


  useEffect(() => {
    const id = data?.id
    id && dispatch(getConfUsers(id))
    setData({...data})
    setConfUsers([userId])
    setSmsSpam(false)
    setTgSpam(false)
  }, [data])
  
  useEffect(() => {
    if (confUsers) {
      const userIds = confUsers.user_info.map(u => u.user_id)
      setConfUsers(userIds)
    }
  }, [confUsers])
  

  const saveButtonClick = async (e) => {
    await dispatch(
      addMode
        ? addConference(_data, _confUsers, smsSpam)
        : editConference(_data, _confUsers)
    )
    closeModal()
    tgSpam && shareConf(e, _data)
  }

  const cancelButtonClick = () => {
    closeModal()
  }

  const handleDataChange = (e) => {
    setData(prev => ({...prev, [e.target.name]: e.target.value}))
  }

  const handleDateChange = (date) => {
    const millisecondDate = moment().valueOf()
    const link = addMode ? `${window.location.origin}/SessionA-${millisecondDate}` : data.url
    setData(prev => ({...prev, date: date, url: link}))
  }

  const handleUserClick = (userId) => {
    const newUsersList = _confUsers.includes(userId)
      ? _confUsers.filter(id => id !== userId)
      : [..._confUsers, userId]
    setConfUsers(newUsersList)  
  }

  const copyButtonClick = () => {
    navigator.clipboard.writeText(_data.url)
    createNotification('success', 'Ссылка скопирована')
  }

  return (
  <Modal
        open={data}
        onClose={closeModal}
        classNames={{
          closeButton: styles.modalCloseBtn,
          modal: styles.confModal
        }}
      >
        {_data &&
        <>
          <h1>{addMode ? 'Редактирование' : 'Добавление'} конференции</h1>
          <div className={styles.inputBlock}>
            <div>Название: </div>
            <input
              value={_data.name}
              onChange={handleDataChange}
              name="name"
            />
          </div>
          <div className={styles.inputBlock}>
            <div>Выберите дату и время: </div>
              <DatePicker
                selected={_data.date ? new Date(_data.date) : null}
                onChange={handleDateChange}
                showTimeInput
                timeFormat="HH:mm"
                timeInputLabel="Время:"
                dateFormat="dd.MM.yyyy - HH:mm"
                locale='ru'
                className={styles.datepickerField}
                showYearDropdown
            />
          </div>
          <div className={styles.inputBlock}>
            <div>Ссылка: </div>
            <input
              value={_data.url}
              disabled
            />
          </div>
          <div className={styles.confUsers}>
            <div>Список участников:</div>
            <div>
              {loading
                ? <Loader left="5rem" size={40}/>
                : <ul>
                  {contacts && contacts.map(user => {
                    const photoLink = user.contact_photo && `${BACKEND_DOMAIN || ''}/${user.contact_photo.path}/${user.contact_photo.unique_filename}`
                    const initials = getInitials(user.last_name, user.first_name)
                    const fullName = `${user.last_name} ${user.first_name} ${user.fathers_name}`
                    const description = `${user.organization} (${user.position || '-'})`
                    return (
                      <li key={user.id} onClick={() => handleUserClick(user.contact_id)}>
                          <CustomCheckbox
                            onClick={(e) => e.stopPropagation()}
                            value={_confUsers.includes(user.contact_id)}
                          />
                          <div>
                            {photoLink
                              ? <img
                                  src={photoLink}
                                  alt='o_O'
                                />
                              : initials
                            }
                          </div>
                          <div className={styles.userSmallDescription}>
                            <div>{fullName}</div>
                            <div>{description}</div>
                          </div>
                      </li> 
                    )
                  }     
                  )}
              </ul>}
            </div>
          </div>
          <div className={styles.shareButtonsBlock}>
            {addMode &&
            <>
              <div
                className={`${styles.tg}  ${tgSpam ? styles.tgActive : ''}`}
                title='Поделиться в Telegram (выберите, чтобы после сохранения конференции подлиться ссылкой в Telegram)'
                onClick={()=> setTgSpam(prev => !prev)}
                disabled={disabledButtons}
              >
                <TbBrandTelegram/>
              </div>
              {/* <div
                className={`${styles.msg} ${smsSpam ? styles.smsActive : ''}`}
                title='Отправить участникам SMS'
                onClick={()=> setSmsSpam(prev => !prev)}
                disabled={disabledButtons}
              >
                <BsEnvelope/>
              </div> */}
            </>}
            <div
              className={styles.lkcp}
              title='Копировать ссылку'
              onClick={copyButtonClick}
              disabled={disabledButtons}
            >
              <TbCopy/>
            </div>
          </div>
          <div className={styles.buttonsBlock} style={{marginTop: "30px"}}>
            <button
              onClick={saveButtonClick}
              className={styles.addButton}
            >
              Сохранить
            </button>
            <button
              onClick={cancelButtonClick}
              className={styles.addButton}
            >
              Отменить
            </button>
          </div>
        </>}
  </Modal>
)
}