import React, { useState, useEffect } from "react";
import styles from "./Form.module.css";
import copy from "../../../../img/icons/copy.png"
import classNames from "classnames";

export const Form = ({
  joinSession,
  mySessionPath,
  changeSessionId,
  myUsername,
  changeUsername,
  myOrganization,
  changeOrganization,
  myPosition,
  changePosition,
  changeMicrophone,
  changeCamera,
  location,
  topic,
  changeTopic
}) => {
  
  const [microphoneOptions, setMicrophoneOptions] = useState([]);
  const [cameraOptions, setCameraOptions] = useState([]);
  const [isInfo, setIsInfo] = useState('');
  const [copyButtonActive, setCopyButtonActive] = useState(false);

  const gettUserMedia = async () => {
    const constraints = { audio: true, video: true}    

    await navigator.mediaDevices.getUserMedia(constraints)
    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      const audio = [];
      const video = [];
      mediaDevices.forEach((mediaDevice) => {
        if (mediaDevice.kind === "audioinput") {
          !audio.length && changeMicrophone(mediaDevice.deviceId);
          audio.push({ name: mediaDevice.label, id: mediaDevice.deviceId });
        }
        if (mediaDevice.kind === "videoinput") {
          !video.length && changeCamera(mediaDevice.deviceId);
          video.push({ name: mediaDevice.label, id: mediaDevice.deviceId });
        }
      });

      if (video.length > 0) {setCameraOptions(video)}
      if (audio.length > 0) {
        setMicrophoneOptions(audio)
      }
      if (audio.length > 0 && audio[0].name === '') {
        setIsInfo('Микрофон не подключен')
      }
    })
  }

  useEffect(() => {
    gettUserMedia();
  }, []);

  const copyButtonClick = () => {
    navigator.clipboard.writeText(mySessionPath)
    setCopyButtonActive(true)
    setTimeout(() => {
      setCopyButtonActive(false)
    }, 1000);
  }

  return (
    <div className={styles.joinDialog}>
      
      <div className={styles.formBlock}>
      <h2>Для подключения к конференции укажите Ваши данные:</h2>
        <form onSubmit={joinSession}>
          <p>
            <div className={styles.labels}>Ссылка на конференцию:</div>
            <input
              className={`${styles.formControl} ${styles.item1}`}
              placeholder='Ссылка на конференцию'
              type="text"
              value={mySessionPath}
              onChange={changeSessionId}
              required
            />
            <div
              className={classNames(styles.copyButton, copyButtonActive && styles.active)}
              title='Скопировать ссылку'
              onClick={copyButtonClick}
            >
              <img src={copy} alt='o_O'/>
            </div>
          </p>
          {!window.location.pathname.toLowerCase().includes('session') &&
          <p>
            <div className={styles.labels}>Тема <br></br>конференции:</div>
            <input
              id="topic"
              className={styles.formControl}
              placeholder="Тема конференции"
              type="text"
              value={topic}
              onChange={changeTopic}
              required
            />
          </p>}
          <p>
            <div className={styles.labels}>Ваше имя:</div>
            <input
              id="userName"
              className={styles.formControl}
              placeholder="Ваше имя"
              type="text"
              value={myUsername}
              onChange={changeUsername}
              required
            />
          </p>
          <p>
            <div className={styles.labels}>Организация:</div>
            <input
              id="organization"
              className={styles.formControl}
              placeholder='Организация'
              type="text"
              value={myOrganization}
              onChange={changeOrganization}
              required
            />
          </p>
          <p>
            <div className={styles.labels}>Должность:</div>
            <input
              id="position"
              className={styles.formControl}
              placeholder='Должность'
              type="text"
              value={myPosition}
              onChange={changePosition}
              required
            />
          </p>
          <p>
            <div className={styles.labels}>Микрофон:</div>
            <select
              className={styles.formControl}
              onChange={(e) => changeMicrophone(e.target.value)}
            >
{/*               <option value="" disabled selected>Микрофон</option>
 */}              {microphoneOptions.length && microphoneOptions.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </p>
          <p>
            <div className={styles.labels}>Камера:</div>
            <select
              className={styles.formControl}
              onChange={(e) => changeCamera(e.target.value)}
            >
{/*               <option value="" disabled selected hidden>Камера</option>
 */}              {cameraOptions.length && cameraOptions.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </select>
          </p>
          <p>
            <input
              className={styles.joinSubmit}
              name="commit"
              type="submit"
              value="подключиться"
            />
{
        isInfo ?
        <div className={styles.info}>{isInfo}</div>
        :
        ''
      }
          </p>
        </form>
      </div>
    </div>
  );
};
