import { useEffect, useState } from "react";
import { ChooseLayout } from "./VideoChatPage/ChooseLayout/ChooseLayout";
import styles from "./VideoChat.module.css";
import VideoChatPage from "./VideoChatPage/VideoChatPageClass";
import Loader from '../../components/Loader/Loader'
import { checkSessionExist, getConfUsers } from '../../actions/auth'
import { useWindowSize } from "../../hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const VideoChat = () => {
  
  const { width } = useWindowSize();
  const history = useHistory()
  const dispatch = useDispatch()
  const {userInfo, token, user_id} = useSelector(state => state.auth)

  const [foolScreenNew, setFoolScreenNew] = useState(undefined);
  const [view, setView] = useState(4);
  const [screen, setScreen] = useState(1);
  const [session, setSession] = useState(false);
  const [openedConference, setOpenedConference] = useState(false)
  const [usersList, setUsersList] = useState(null)

  useEffect(() => {
    const location = history.location.pathname
    const sessionId = location.toLocaleLowerCase().split('sessiona-')[1]
    if (sessionId) {
      checkBySessionId(sessionId, location)
    } else {
      setOpenedConference(true)
    }
  }, [])
  
  useEffect(() => {
    width < 560 ? setScreen(5) : setScreen(view);
  }, [width]);

  useEffect(() => {
    setScreen(view);
  }, [view]);


  const checkBySessionId = async (sessionNumber, path) => {
    const sessionId = await dispatch(checkSessionExist(sessionNumber))
    if (sessionId) {
      if (token) {
        const usersList = await dispatch(getConfUsers(sessionId))
        const data = usersList.filter(user => user.user_id !== user_id)
        setUsersList(data)
      } else {
        history.push({
          pathname: '/auth',
          state: { sessionPath: path }
        })
      }
    } else {
      setOpenedConference(true)
    }
  }


  return (
    <>
      {session && width > 560 && <ChooseLayout view={view} changeView={setView} />}
      {(openedConference || usersList)
        ? <div className={styles.container}>
            <VideoChatPage
              foolScreenNew={foolScreenNew}
              setFoolScreenNew={setFoolScreenNew}
              changeView={setView}
              view={screen}
              userInfo={userInfo}
              token={token}
              setAppSession={setSession}
              usersList={usersList}
              screenWidth={width}
            />
          </div>
        : <Loader top="50%" left='50%' />
      }
    </>
  );
};
