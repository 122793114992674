import storage from 'redux-persist/es/storage';
import { persistCombineReducers } from 'redux-persist';

import auth from './auth';
import lk from './lk';

const storageConfig = { whitelist: 'auth', key: 'kursant_storage', storage };

export default persistCombineReducers(storageConfig, {
  auth,
  lk
});