import { BACKEND_URL_PREFIX } from "../assets/const";
import { buildDefaultHeaders } from "./helper";
import { checkAndUpdateToken, getUserInfo } from "./auth";
import {
  SET_CONF_USERS,
  SET_LK_LOADING,
  SET_NEW_USER_PHOTO,
  SET_USERS_LIST,
  SET_USER_CONFERENCES,
  SET_USER_CONTACTS,
} from "./types";
import { createNotification } from "../components/Notifications/Notifications";
import moment from "moment";

export const getContacts = (userId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/contacts/${userId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_USER_CONTACTS, payload: data.contacts });
    }
  }
};

export const addContacts = (user_id, user_ids) => async (dispatch, getState) => {
  
  dispatch({type: SET_LK_LOADING, payload: true})
  
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const data = {user_id, user_ids}  
    const response = await fetch(`${BACKEND_URL_PREFIX}/contacts`, {
          method: "POST",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        })
      if (response.ok) {
        dispatch(getContacts(user_id))
  }
  dispatch({type: SET_LK_LOADING, payload: false})
}
}

export const deleteContact = (userId, contactId) => async (dispatch, getState) => {
  dispatch({type: SET_LK_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/contacts/${userId}/${contactId}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      dispatch(getContacts(userId))
    }
  }
  dispatch({type: SET_LK_LOADING, payload: false})
};

export const getUsers = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const urlParams = new URLSearchParams(params).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/user?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const payload = data.users;
      dispatch({ type: SET_USERS_LIST, payload });
    }
  }
};

export const getConferences = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/meeting`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const payload = data.meetings;
      dispatch({ type: SET_USER_CONFERENCES, payload });
    }
  }
};


export const addConference = (data, confUsers, smsSpam) => async (dispatch, getState) => {
  
  dispatch({type: SET_LK_LOADING, payload: true})

  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/meeting`, {
      method: "POST",
      body: JSON.stringify({
         ...data, 
         date: moment(data.date).format('yyyy-MM-DD HH:mm:ss'), 
         url: data.url
        }),
      ...buildDefaultHeaders(getState),
    })
    if (response.ok) {
      await dispatch(getConferences())
      const data = await response.json()
      const confId = data.meeting.id
      confUsers && dispatch(setConfUsers(confId, confUsers))
      smsSpam && await dispatch(sendSmsForConference(confId))
    }
    dispatch({ type: SET_LK_LOADING, payload: false })
  }
}

export const editConference = (data, confUsers) => async (dispatch, getState) => {
  
  dispatch({type: SET_LK_LOADING, payload: true})
  
  const confId = data.id
  delete data.id
  delete data.url

  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/meeting/${confId}`, {
          method: "PUT",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        })
      if (response.ok) {
        dispatch(getConferences())
        confUsers && dispatch(setConfUsers(confId, confUsers))
  }
  dispatch({type: SET_LK_LOADING, payload: false})
}
}

export const deleteConference = (confId) => async (dispatch, getState) => {
  dispatch({type: SET_LK_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    try {
      const response = await fetch(`${BACKEND_URL_PREFIX}/meeting/${confId}`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      dispatch(getConferences())
    } else {
      if (response.status === 403) {
        const data = await response.json()
        createNotification('error', data.error)
      }
    }
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({type: SET_LK_LOADING, payload: false})
    }
  }
};

export const getConfUsers = (confId) => async (dispatch, getState) => {
  
  dispatch({type: SET_LK_LOADING, payload: true})
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/users_to_meetings/meeting/${confId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const payload = data.users_to_meeting;
      console.log(payload)
      dispatch({ type: SET_CONF_USERS, payload });
    }
  }
  dispatch({type: SET_LK_LOADING, payload: false})

};

export const setConfUsers = (confId, idsList) => async (dispatch, getState) => {
  
  dispatch({type: SET_LK_LOADING, payload: true})
  const data = {user_ids: idsList}
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    try {
      const response = await fetch(`${BACKEND_URL_PREFIX}/users_to_meetings/meeting/${confId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
        console.log("ok")
      }
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({type: SET_LK_LOADING, payload: false})
    }
}
}

export const uploadUserPhoto = (file) => async (dispatch, getState) => {
  
  dispatch({type: SET_LK_LOADING, payload: true})
  
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {

    const userId = getState().auth.user_id
    const fd = new FormData()
    fd.append("file", file);


    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${userId}/avatar`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
      body: fd,
    })
      if (response.ok) {
        createNotification('success', 'Фотография успешно изменена')
        // dispatch({type: SET_NEW_USER_PHOTO, payload: file})
        dispatch(getUserInfo(userId))
    }
  dispatch({type: SET_LK_LOADING, payload: false})
}
}

export const deleteUserPhoto = () => async (dispatch, getState) => {
  dispatch({type: SET_LK_LOADING, payload: true})
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const userId = getState().auth.user_id

    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${userId}/avatar`,
      {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      }
    );
    if (response.ok) {
      createNotification('success', 'Фотография успешно удалена')
      dispatch({type: SET_NEW_USER_PHOTO, payload: null})
    }
  }
  dispatch({type: SET_LK_LOADING, payload: false})
};

export const sendSmsForConference = (confId) => async (dispatch, getState) => {
  
  dispatch({type: SET_LK_LOADING, payload: true})
  
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {
    const data = {user_ids: []}
    const response = await fetch(`${BACKEND_URL_PREFIX}/users_to_meetings/meeting/${confId}/users/notify`, {
          method: "POST",
          body: JSON.stringify(data),
          ...buildDefaultHeaders(getState),
        })
      if (response.ok) {
        createNotification('success', 'Сообщения участникам успешно отправлены')
  }
  dispatch({type: SET_LK_LOADING, payload: false})
}
}