
export const getInitials = (word1, word2) => {
    const letter1 = word1 && word1.split()[0]
    const letter2 = word2 && word2.split()[0]
    return `${letter1 || ''}${letter2 || ''}`.toUpperCase()
}

export function debounce(func, wait, immediate) {
  
  
  let timeout;
  
  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};