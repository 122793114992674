import styles from './Chat.module.css'
import React, { useEffect, useState } from 'react'
import img from '../../../../img/icons/voiceNotActive.png'
import { useRef } from 'react'
import { createNotification } from '../../../../components/Notifications/Notifications'

export const Chat = ({ session, messages, setMessages, visibleChat, setInvisible }) => {
  
  const ref = useRef()
  const chatRef = useRef()
  const [inputValue, setInputvalue] = useState('')

  const sendMessage = e => {
    if (e.key === 'Enter') {
      session.signal({
        data: e.target.value,
        type: 'my-chat',
      })
      setInputvalue('')
    }
  }

  useEffect(() => {
    if (ref.current) ref.current.scroll(0, 9999)
  }, [messages])

  useEffect(() => {
    session.on('signal:my-chat', event => {
      const clientName = JSON.parse(event.from.data).clientName
      const newMessage = {
        message: event.data,
        isPublisher: event.from.role,
        name: clientName,
        time: new Date().toLocaleString().substr(11, 6)
      }
      createNotification('info', `Новое сообщение от: ${clientName}`)
      setMessages(newMessage)
    })
  }, [])

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick);
    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    }
  }, []);

  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    if (chatRef.current && !path.includes(chatRef.current)) {
      setInvisible()
    }
  };

  if (!visibleChat) return null
  return (
    <div className={styles.wrapper}>
      <div className={styles.chat} ref={chatRef}>
        <div className={styles.messages} ref={ref}>
          {messages.map(({ message, isPublisher, name, time }) => {
            return isPublisher ? (
              <div class={styles.myMessage}>
                <span className={styles.myName}>{name}</span>
                <p>{message}</p>
                <div class={styles.myTime}>{time}</div>
              </div>
            ) : (
              <div class={styles.message}>
                <span className={styles.name}>{name}</span>
                <p>{message}</p>
                <div class={styles.time}>{time}</div>
              </div>
            )
          })}
        </div>

        <input
          autoFocus
          placeholder='Введите сообщение...'
          value={inputValue}
          onChange={e => setInputvalue(e.target.value)}
          className={styles.input}
          type='text'
          onKeyDown={sendMessage}
        />
      </div>
    </div>
  )
}
