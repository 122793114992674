import React from 'react'
import arrowLeft from '../../../../../img/arrowLeft.png'
import arrowRight from '../../../../../img/arrowRight.png'
import { Video } from '../Video/Video'
import styles from './SubscribersList.module.css'

export const SubscribersList = ({ users }) => {
  return (
    <div className={styles.container}>
      {users.length !== 0 && <img src={arrowLeft} style={{ width: '80px' }} />}
      <div className={styles.subscribers}>
        {users.map((user, i) => (
          <div key={i} className={styles.streamContainer}>
            <Video streamManager={user} subscribers={true}/>
          </div>
        ))}
      </div>
      {users.length !== 0 && <img src={arrowRight} style={{ width: '80px', marginLeft: '20px' }} />}
    </div>
  )
}
