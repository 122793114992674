import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../LK.module.css";
import { editUserInfo, changePassword } from '../../../actions/auth'
import { uploadUserPhoto, deleteUserPhoto } from '../../../actions/lk'
import Loader from "../../../components/Loader/Loader";
import { createNotification } from "../../../components/Notifications/Notifications";
import { useFormik } from "formik";
import * as yup from 'yup'
import {
  IoMdCreate,
  IoIosClose,
} from "react-icons/io";
import { BACKEND_DOMAIN } from "../../../assets/const";


const settingItems = [
  {name: 'Фамилия', value:  'last_name'},
  {name: 'Имя', value:  'first_name'},
  {name: 'Отчество', value:  'fathers_name'},
  {name: 'Организация', value:  'organization'},
  {name: 'Должность', value:  'position'},
  {name: 'Телефон', value:  'phone'},
  {name: 'Email', value:  'email'},
  {name: 'Telegram', value:  'telegram_id'}
]

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const changePasswordLabels = ['Введите старый пароль', 'Введите новый пароль', 'Подтвердите пароль']

const schema = yup.object().shape({
  last_name: yup.string().required('Поле является обязательным'),
  first_name: yup.string().required('Поле является обязательным'),
  fathers_name: yup.string().required('Поле является обязательным'),
  organization: yup.string().required('Поле является обязательным'),
  position: yup.string().required('Поле является обязательным'),
  phone: yup.string().required('Поле является обязательным').matches(phoneRegExp, 'Телефонный номер формата 89997654321'),
  email: yup.string().required('Поле является обязательным').email('Email неверного формата'),
})

export const Settings = () => {
  
  const { userInfo, loading, username } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const userPhoto = userInfo.profile_photo


  const [passwordInputs, setPasswordInputs] = useState([])

  // console.log(userInfo)

  const formik = useFormik({
    initialValues: userInfo,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: values => dispatch(editUserInfo(values))
  })

  const cancelButtonClick = () => {
    formik.setValues(userInfo)
    setPasswordInputs([])
  }
  
  const passwordChangeButtonClick = async () => {
    if (passwordInputs.length === 3) {
      let wrong
      passwordInputs.includes('') && ( wrong = 'Одно из полей не заполнено' )
      passwordInputs[1] !== passwordInputs[2] && ( wrong = 'Новый пароль не совпадает' )
      if (wrong) {
        createNotification('error', wrong)
      } else {
        const payload = {
          username: username,
          old_password: passwordInputs[0],
          new_password: passwordInputs[1]  
        }
        const successChange = await dispatch(changePassword(payload))
        successChange && setPasswordInputs([])
      }
    } else {
      setPasswordInputs([...passwordInputs, ''])
    }
  }

  const handlePasswordInput = (e, ind) => {
    const newPassList = [...passwordInputs]
    newPassList[ind] = e.target.value
    setPasswordInputs(newPassList)
  }

  const editImageClick = () => {
    const input = document.getElementById(`inputImage`)
    input.click()
  }
  
  const deleteImageClick = () => {
    dispatch(deleteUserPhoto())
  }

  const handleAvatarChange = (e) => {
    const file = e.target.files[0]
    dispatch(uploadUserPhoto(file))
  }
    
  return (
    <>
      <h1>Настройки</h1>
      <div className={styles.settingsWrap}>
      <div>
        {loading
          ? <Loader top="40%" left="45%"/>
          : settingItems.map(item => {
            const fieldError = formik.errors[item.value]
            const numberType = item.value==='phone'
            return (
              <div
                className={`${styles.inputBlock} ${fieldError ? styles.errorInput : ''}`}
                key={item.value}
                title={fieldError}
              >
                <div>{item.name}: </div>
                <input
                  value={formik.values[item.value]}
                  onChange={formik.handleChange}
                  name={item.value}
                  type={numberType? 'number' : 'text'}
                />
              </div>
            )
          })}
      </div>
      <div>
        <div className={styles.imageBox}>
          <IoMdCreate onClick={editImageClick} />
          <IoIosClose onClick={deleteImageClick} />
          <img
            src={userPhoto
                ? userPhoto.path
                  ? `${BACKEND_DOMAIN || ''}/${userPhoto.path}/${userPhoto.unique_filename}`
                  : URL.createObjectURL(userPhoto)
                : '/photoplace.png'}
            alt='о_О'
          />
          <input
            type="file"
            id="inputImage"
            accept="image/png, image/jpeg"
            onChange={handleAvatarChange}
            hidden
          />
        </div>
        <div className={styles.passwordBlock}>
          {passwordInputs.map((input, ind) =>
            <div key={ind} className={styles.passInputBlock}>
              <div>{changePasswordLabels[ind]}:</div>
              <input
                value={input}
                onChange={(e) => handlePasswordInput(e, ind)}
                type='password'
              />
            </div>
          )

          } 
          <button
            onClick={passwordChangeButtonClick}
            className={styles.addButton}
          >
            {passwordInputs.length && passwordInputs.length !== 3 ? 'Подтвердить' : 'Сменить пароль'}
          </button>
        </div>
      </div>
      <div>
        <button
          onClick={formik.handleSubmit}
          className={styles.addButton}
        >
          Сохранить
        </button>
        <button
          onClick={cancelButtonClick}
          className={styles.addButton}
        >
          Отменить
        </button>
      </div>
      </div>
    </>
  );
};
