import React from "react";
import styles from "./Layout.module.css";
import { format } from "date-fns/esm";
import { MONTHS, version } from "../../assets/const";

import emblem from "../../img/emblem.png";
import label from "../../img/label.png";
import LMS from "../../img/LMS.png";
import logo from "../../img/logo.png";
import logo_MO from "../../img/logo_MO.png";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from '../../actions/auth'
import { useHistory, useLocation } from "react-router";
import { useEffect } from "react";

export default function Layout({ children }) {
  const TARGET = process.env.REACT_APP_TARGET;

  const { width } = useWindowSize();

  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const history = useHistory()
  const location = useLocation()
  
  useEffect(() => {
    location.pathname==='/lk' && !token && history.push('/auth')
  }, [location, token])
  
  
  const logOut = () => {
    dispatch(signOut())
  }

  const getDate = () => {
    const year = format(new Date(), "yyyy");
    const month = MONTHS[+format(new Date(), "MM")];
    const day = format(new Date(), "dd");

    return `КОНФЕРЕНЦИЯ ${day} ${month} ${year} Г.`;
  };

  return (
    <div className={styles.container}>
      <header /* style={foolScreenNew && {position : "absolute", top: "0", left : "20%"}} */>
        {width < 895 && (
          <div>
            <div className={styles.conferenceHead}>
              <h2>Система дистанционного обучения &#171;Курсант&#187;</h2>
              <h1>{getDate()}</h1>
            </div>
          </div>
        )}
        <div className={styles.header}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={TARGET === "kursant" ? emblem : label}
              alt="o_o"
              className={styles.logo}
              onClick={() => history.push('/lk')}
            />
          </div>
          {width > 895 && (
            <div>
              <div
                className={
                  styles.conferenceHead
                } /* style={foolScreenNew && {zIndex : "1000"}} */
              >
                <h2>Система дистанционного обучения &#171;Курсант&#187;</h2>
                <h1>{getDate()}</h1>
              </div>
            </div>
          )}
          <div className={styles.images}>
            <img src={logo} alt="o_o" />
            {TARGET === "kursant" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  alignItems: "center",
                }}
              >
                <img src={logo_MO} className={styles.logos} alt="o_o" />
                <img src={LMS} className={styles.logos} alt="o_o" />
              </div>
            )}
          </div>
        </div>
        {token && <div className={styles.exitButton} onClick={logOut}>выход</div>}
      </header>
      <div className={styles.content}>
        {children}
      </div>
      <div className={styles.version}>{version}</div>
    </div>
  );
}
