import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "../components/Layout/Layout";

import Auth from "../pages/Auth/Auth";
import { VideoChat } from "../pages/VideoChat/VideoChat"
import LK from '../pages/LK/LK'

const regex = /\d+/

export const PATHS = {
    ROOT: "/auth",
    AUTH: {
      
      CONF: `/`,
      LK: "/lk"
    },
  };
  
  export default function Routes() {
    return (
      <BrowserRouter>
          <Layout>
            <Switch>
              <Route exact path={PATHS.ROOT} component={Auth} />
              <Route exact path={PATHS.AUTH.LK} component={LK} />
              <Route path={PATHS.AUTH.CONF} component={VideoChat} />
            </Switch>
          </Layout>
      </BrowserRouter>
    );
  }