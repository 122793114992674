import React, { useEffect, useState } from "react";
import styles from "../LK.module.css";
import { useDispatch, useSelector } from "react-redux";
import { addContacts, deleteContact, getUsers } from "../../../actions/lk";
import Loader from "../../../components/Loader/Loader";
import { AiOutlineSearch, AiOutlineClose } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox'
import moment from "moment";
import { BACKEND_DOMAIN } from "../../../assets/const";
import { getInitials } from "../../../utils/smallHelpers";


export const Contacts = () => {
  
  const [contactsList, setContactsList] = useState(false)
  const [addContactsList, setAddContactsList] = useState([])

  const userId = useSelector((state) => state.auth.user_id);
  const { contacts, users, loading } = useSelector((state) => state.lk);
  const dispatch = useDispatch();
  
  
  

  const addButtonClick = async () => {
    if (contactsList) {
      await dispatch(addContacts(userId, addContactsList))
      setContactsList(false)
      setAddContactsList([])
    } else {
      setContactsList(true)
    }
  }

  const cancelButtonClick = () => {
    setContactsList(false)
    setAddContactsList([])
  }

  const handleUserClick = (userId) => {
    let newUsersList
    if (addContactsList.includes(userId)) {
      newUsersList = addContactsList.filter(contact => contact !== userId)
    } else {
      newUsersList = [...addContactsList, userId]
    }
    setAddContactsList(newUsersList)
  }

  const handleDeleteContact = (contactId) => {
    dispatch(deleteContact(userId, contactId))
  }

  const renderLi = (user) => {
    const createdDate = moment(user.created_at).format('DD.MM.YYYY')
    const photoLink = user.contact_photo && `${BACKEND_DOMAIN || ''}/${user.contact_photo.path}/${user.contact_photo.unique_filename}`
    const initials = getInitials(user.last_name, user.first_name)
    const fullName = `${user.last_name || ''} ${user.first_name || ''} ${user.fathers_name || ''}`
    const description = `${user.organization || ''} (${user.position || 'Должность не указана'})`
    return (
      <li key={user.id}>
          <div>
            {photoLink
              ? <img
                  src={photoLink}
                  alt='o_O'
                />
              : initials
            }
          </div>
          <div className={styles.userDescription}>
              <div>{fullName}</div>
              <div>{description}</div>
          </div>
          <AiOutlineClose onClick={() => handleDeleteContact(user.contact_id)}/>
      </li> 
    )
  }
 
  const onSearchChange = (fio) => {
    dispatch(getUsers({fio}))
  }
  
  return (
    <>
      <h1>Контакты</h1>
      {loading && !contactsList ? (
        <Loader top="52%" left="47%" />
      ) : (
        <div className={styles.contactsWrap}>
          <div className={styles.contactsList}>
            <ul>
              {contacts?.map(renderLi)}
            </ul>
          </div>
          <div>
            <SearchInput onChange={onSearchChange} />
            <div className={styles.contactAddBlock}>
                <div className={contactsList ? styles.listBlock_active : styles.listBlock}>
                    {loading && contactsList ? (
                        <Loader top="45%" left="40%" size={40} />
                    ) : (
                        <ul>
                            {users?.length
                              ?   users?.filter(it => it.id !== userId)?.map(user => {
                                  const alreadyAdded = contacts && contacts.map(it => it.contact_id).includes(user.id)
                                  const photoLink = user.profile_photo && `${BACKEND_DOMAIN || ''}/${user.profile_photo.path}/${user.profile_photo.unique_filename}`
                                  const initials = getInitials(user.last_name, user.first_name)
                                  const fullName = `${user.last_name} ${user.first_name} ${user.fathers_name}`
                                  const description = `${user.organization} (${user.position || '-'})`
                                  return (
                                    <li key={user.id} onClick={() => !alreadyAdded && handleUserClick(user.id)}>
                                        <CustomCheckbox
                                          onClick={(e) => e.stopPropagation()}
                                          value={addContactsList.includes(user.id)||alreadyAdded}
                                          disabled={alreadyAdded}
                                        />
                                        <div>
                                          {photoLink
                                            ? <img
                                                src={photoLink}
                                                alt='o_O'
                                              />
                                            : initials
                                          }
                                        </div>
                                        <div className={styles.userSmallDescription}>
                                          <div>{fullName}</div>
                                          <div>{description}</div>
                                        </div>
                                    </li> 
                                  )
                              })     
                              :  <li className={styles.noUsersLi}>Ничего не найдено</li>
                            }
                        </ul>
                    )}
                </div>
                <div className={styles.buttonsBlock}>
                <button
                  className={styles.addButton}
                  onClick={addButtonClick}
                >
                    <IoMdAdd size={30}/>
                    {`добавить ${contactsList ? '' : 'контакт'}`}
                </button>
                {contactsList &&
                <button
                  className={styles.addButton}
                  onClick={cancelButtonClick}
                >
                    отменить
                </button>}
                </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};


const SearchInput = ({ onChange }) => {
  
  const [searchString, setSearchString] = useState('')
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    if (!searchString && firstRender) return;
    firstRender && setFirstRender(false)
    const timeout = setTimeout(() => {
      onChange(searchString)
    }, 1000);
    return () => {
      clearTimeout(timeout)
    }
  }, [searchString])
  
  return (
    <label className={styles.searchInput}>
        <AiOutlineSearch/>
        <input
          value={searchString}
          onChange={e => setSearchString(e.target.value)}
        />
        {searchString &&
        <AiOutlineClose onClick={() => setSearchString('')} />}
    </label>
  )
}