import React, { Component } from 'react'
import styles from './FoolScreen.module.css'
import fullScreenIcon from '../../../../img/icons/fullScreen.png'
import { Controllers } from '../Controllers/Controllers'
import { Tooltip } from '../Tooltip/Tooltip'
import videoActive from '../../../../img/icons/video-stream-active.png'
import videoNotActive from '../../../../img/icons/video-stream-not-active.png'
import voiceActive from '../../../../img/icons/voice-active.png'
import voiceNotActive from '../../../../img/icons/voiceNotActive.png'

export class FoolScreen extends Component {
  constructor(props) {
    super(props)
    this.videoRef = React.createRef()
  }

  componentDidUpdate(props) {
    if (props && !!this.videoRef) {
      this.props.streamManager.addVideoElement && this.props.streamManager.addVideoElement(this.videoRef.current)
    }
  }

  componentDidMount() {
    if (this.props && !!this.videoRef) {
      this.props.streamManager.addVideoElement && this.props.streamManager.addVideoElement(this.videoRef.current)
    }
  }

  render() {
    const { state, togglePublishAudio, togglePublishVideo} = this.props
    return (
      <div className={styles.foolScreen}>
        <video autoPlay={true} ref={this.videoRef} />
        <img src={fullScreenIcon} alt="o_O" onClick={() => this.props.handleFoolScreenVideoStream(this.props.streamManager)} />
        <div className={styles.controllers}>
          <Tooltip content='микрофон'>
            <img
              src={state.publisher.stream.audioActive ? voiceActive : voiceNotActive}
              alt="o_O"
              onClick={togglePublishAudio}
            />
          </Tooltip>
          <Tooltip content='камера'>
            <img
              src={state.publisher.stream.videoActive ? videoActive : videoNotActive}
              alt="o_O"
              onClick={togglePublishVideo}
            />
          </Tooltip>
        </div>
      </div>
    )
  }
}
