import React from 'react'
import styles from './SwapUsersSelect.module.css'

export const SwapUsersSelect = ({ users = [], swapUsers }) => {
  if (!users.length) return null



  return (
    <div className={styles.wrapper}>
      <h1>Выбрать другого участника:</h1>
      {users.map(user => (
        <div onClick={() => swapUsers(user)} key={user.stream.id}>
          {JSON.parse(user.stream.connection.data).clientName +
            ' ' +
            JSON.parse(user.stream.connection.data).clientOrganization +
            ' ' +
            JSON.parse(user.stream.connection.data).clientPosition}
        </div>
      ))}
    </div>
  )
}
