import React, { Component } from 'react'
import styles from './NewFoolScreen.module.css'
import fullScreenIcon from '../../../../img/icons/fullScreen.png'
import { UserVideoComponent } from '../View1/UserVideoComponent/UserVideoComponent'
import { OpenViduVideoComponent } from '../View1/OvVideo/OvVideo'
import { VideoComponent } from '../View1/UserVideoComponent/VideoComponent'

export class NewFoolScreen extends Component {
  constructor(props) {
    super(props)
    this.videoRef = React.createRef()
  }

  componentDidUpdate(props) {
    if (props && !!this.videoRef) {
      this.props.streamManager.addVideoElement && this.props.streamManager.addVideoElement(this.videoRef.current)
    }
  }

  componentDidMount() {
    if (this.props && !!this.videoRef) {
      this.props.streamManager.addVideoElement && this.props.streamManager.addVideoElement(this.videoRef.current)
    }
  }

  render() {
    return (
      <div className={styles.foolScreen}>
        <img src={fullScreenIcon} alt="o_O" onClick={() => this.props.handleFoolScreenVideoStream(this.props.streamManager)} />
        <video autoPlay={true} ref={this.videoRef} />
        <div className={styles.subscribersBlock}>
          {this.props.subscribers.map(item => <VideoComponent streamManager={item} />)}
        </div>
      </div>
    )
  }
}
