import React from 'react'
import cahtIcon from '../../../../img/icons/caht.png'
import fullScreenIcon from '../../../../img/icons/fullScreen.png'
import leaveSessionIcon from '../../../../img/icons/leaveSession.png'
import shareScreenActive from '../../../../img/icons/shareScreenActive.png'
import shareScreenDisable from '../../../../img/icons/shareScreenDisable.png'
import shareScreenNotActive from '../../../../img/icons/shareScreenNotActive.png'
import videoActive from '../../../../img/icons/video-stream-active.png'
import videoNotActive from '../../../../img/icons/video-stream-not-active.png'
import voiceActive from '../../../../img/icons/voice-active.png'
import voiceNotActive from '../../../../img/icons/voiceNotActive.png'
import { Tooltip } from '../Tooltip/Tooltip'
import styles from './Controllers.module.css'

export const Controllers = ({
  state,
  view,
  togglePublishAudio,
  togglePublishVideo,
  removeShareScreen,
  shareScreen,
  leaveSession,
  handleFoolScreenVideoStream,
  shareScreenSubscriber,
  toggleVisibleChat,
  positionDown
}) => {
  
  const handleChatClick = (e) => {
    e.stopPropagation()
    // handleFoolScreenVideoStream(state.publisher)
    toggleVisibleChat()
  }
  
  return (
    <div className={`${styles.wrapper} ${positionDown && styles.positionDown}`}>
      {!state.isLeader &&
        <>
          <Tooltip content='микрофон'>
            <img
              src={state.publisher.stream.audioActive ? voiceActive : voiceNotActive}
              alt="o_O"
              onClick={togglePublishAudio}
            />
          </Tooltip>
          <Tooltip content='камера'>
            <img
              src={state.publisher.stream.videoActive ? videoActive : videoNotActive}
              alt="o_O"
              onClick={togglePublishVideo}
            />
          </Tooltip>
          <Tooltip content='показать экран'>
            <img
              src={ state.publisher2 && shareScreenSubscriber()
                  ? shareScreenActive
                  : shareScreenSubscriber()
                  ? shareScreenDisable
                  : shareScreenNotActive
              }
              alt="o_O"
              onClick={state.publisher2 && shareScreenSubscriber() ? removeShareScreen : shareScreen}
            />
          </Tooltip>
        </>
      }
      <Tooltip content='покинуть сеанс'>
        <img
          src={leaveSessionIcon}
          alt="o_O"
          onClick={leaveSession}
        />
      </Tooltip>
      <Tooltip content='на весь экран'>
        <img
          src={fullScreenIcon}
          alt="o_O"
          onClick={() => handleFoolScreenVideoStream(state.publisher)}
        />
      </Tooltip>
      <Tooltip content='Чат'>
        <img
          src={cahtIcon}
          alt="o_O"
          onClick={handleChatClick}
        />
      </Tooltip>
    </div>
  )
}
