import Tippy from '@tippy.js/react'

export const Popover = ({ children, ...rest }) => <Tippy {...rest}>{children}</Tippy>

Popover.defaultProps = {
  arrow: true,
  animateFill: false,
  animation: 'scale',
  interactive: true,
  interactiveBorder: 10,
  theme: 'translucent',
  trigger: 'click',
  duration: 0,
  hideOnClick: true
}
