import React from 'react'
import styles from './AuthForm.module.css'
import { useFormik } from 'formik';

export default function AuthForm({
    submitForm,
    loading
  }) {

    const authForm = useFormik({
        initialValues: {username: "", password: ""},
        onSubmit: submitForm
      });
    

    return (
        <form className={styles.form} onSubmit={authForm.handleSubmit}>
            <input placeholder="логин" name="username" onChange={authForm.handleChange}/>
            <input placeholder="пароль" name="password" type="password" onChange={authForm.handleChange}/>
            <button
                disabled={loading}
            >
              Войти
            </button>
        </form>
    )
}
