import React from "react";
import styles from "./CustomCheckbox.module.css"
import { MdCheck } from "react-icons/md";


const CustomCheckbox = ({
    label,
    value,
    color,
    children,
    ...props
  }) => {
  
  const colorStyles = color ? {
    color: color,
    borderColor: color
  } : null

  return (
    <div className={styles.wrapper}>
      <label className={styles.checkbox}>
        <input type="checkbox" checked={value} {...props} />
        <i style={colorStyles} className={styles.fakeBox}>{value && <MdCheck size={20}/>}</i>
        <span className={styles.span}>{label||children}</span>
      </label>
    </div>
  );
};

export default CustomCheckbox;
