export const SIGNED_OUT = 'SIGNED_OUT'
export const AUTH_COMPLETED = 'AUTH_COMPLETED'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'
export const SET_AUTH_USER_INFO = 'SET_AUTH_USER_INFO'
export const SET_NEW_USER_PHOTO = 'SET_NEW_USER_PHOTO'

export const SET_USER_CONTACTS = 'SET_USER_CONTACTS'
export const SET_USER_CONFERENCES = 'SET_USER_CONFERENCES'
export const SET_CONF_USERS = 'SET_CONF_USERS'
export const SET_USERS_LIST = 'SET_USERS_LIST'
export const SET_LK_LOADING = 'SET_LK_LOADING'
