import React, { useEffect, useState } from 'react'
import styles from './LK.module.css'
import { AiOutlineVideoCamera, AiOutlineContacts, AiOutlineSetting } from 'react-icons/ai';
import { getContacts, getUsers } from "../../actions/lk";

import {Contacts} from './LKComponents/Contacts'
import {Conferences} from './LKComponents/Conferences'
import {Settings} from './LKComponents/Settings'
import { useDispatch, useSelector } from 'react-redux';



const menuItems = [
  {
    name: 'Конференции',
    value: 'conferences',
    icon: <AiOutlineVideoCamera/>
  },
  {
    name: 'Контакты',
    value: 'contacts',
    icon: <AiOutlineContacts/>
  },
  {
    name: 'Настройки',
    value: 'settings',
    icon: <AiOutlineSetting/>
  }
]

export default function LK() {
  
  const [mode, setMode] = useState('conferences')
  
  const userId = useSelector((state) => state.auth.user_id);
  const dispatch = useDispatch();
  const { contacts, users } = useSelector((state) => state.lk);


  useEffect(() => {
    !contacts && dispatch(getContacts(userId))
    !users && dispatch(getUsers())
  }, []);

  const renderContent = () => {
    switch (mode) {
      case 'contacts':
        return <Contacts/>
    
      case 'conferences':
        return <Conferences/>
    
      case 'settings':
        return <Settings/>
    
      default:
        return
    }
  }
  
  return (
    <div className={styles.wrap}>
      <div className={styles.menu}>
        {menuItems.map(item =>
          <div
            key={item.value}
            onClick={() => setMode(item.value)}
            className={`${styles.item} ${mode===item.value && styles.activeItem}`}
          >
            <i>{item.icon}</i>
            {item.name}
          </div>
        )}
      </div>
      <div className={styles.content}>
        {renderContent()}
      </div>
    </div>
  )
}