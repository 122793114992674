import { BACKEND_URL_PREFIX } from "../assets/const";
import { buildDefaultHeaders } from "./helper";
import moment from 'moment'
import { AUTH_COMPLETED, SET_AUTH_LOADING, SET_AUTH_USER_INFO, SIGNED_OUT } from "./types";
import { createNotification } from "../components/Notifications/Notifications";

const TOKEN_UPDATING_INTERVAL = 190;

const getTimeToExpireToken = (getState) => {
  const { expires } = getState().auth;
  return moment(expires).diff(moment.utc()) / 1000 / 60;
};

export const checkAndUpdateToken = () => async (dispatch, getState) => {
  const timeToExpire = getTimeToExpireToken(getState);
  if (timeToExpire < TOKEN_UPDATING_INTERVAL) {
    try {
      const response = await fetch(
        `${BACKEND_URL_PREFIX}/token`,
        buildDefaultHeaders(getState)
        );
      if (response.ok) {
        const result = await response.json();
        if (result.error && result.error.code === 401) {
          dispatch(signOut())
        } else {
          dispatch({ type: AUTH_COMPLETED, user: result });
        }
      } else {
        dispatch(signOut())
      }
    } catch (ERR) {
      dispatch(signOut())
    }
  }

};

export const signOut = () => async (dispatch) => {
  dispatch({ type: SIGNED_OUT });
  window.location.href = "/auth";
};

export const auth = (values) => async (dispatch, getState) => {
  dispatch({ type: SET_AUTH_LOADING, payload: true });
  try {
    const { username, password } = values;
    const tok = `${username.trim()}:${password.trim()}`;
    const hash = window.btoa(unescape(encodeURIComponent(tok)));
    const Authorization = `Basic ${hash}`;

    const response = await fetch(`${BACKEND_URL_PREFIX}/token`, {
      headers: {
        Authorization,
        "Access-Control-Allow-Origin": "*",
      },
    });

    if (response.ok) {
      const result = await response.json();
      if (result.error && result.error.code === 401) {
        createNotification("error", "Неверное имя пользователя или пароль");
      } else {
        dispatch({ type: AUTH_COMPLETED, user: result });
        const userId = result.user_id
        await dispatch(getUserInfo(userId))
      }
    } else if (response.status === 401) {
        createNotification("error", "Неверное имя пользователя или пароль");
    }
  } catch (ERROR) {
    createNotification("error", "Сбой авторизации");
    console.log(ERROR);
  } finally {
    dispatch({ type: SET_AUTH_LOADING, payload: false });
  }
};

export const getUserInfo = (userId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/user/${userId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      const payload = data.user;
      dispatch({ type: SET_AUTH_USER_INFO, payload });
    }
  }
};

export const editUserInfo = (_data) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken())
  if (getState().auth.token) {

    dispatch({type: SET_AUTH_LOADING, payload: true})
    
    const data = {..._data}

    const userId = data.id
    delete data.id
    delete data.created_at
    delete data.username


    try {
      const response = await fetch(`${BACKEND_URL_PREFIX}/user/${userId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      })
      if (response.ok) {
        const responseData = await response.json()
        const payload = responseData.result.user_info
        dispatch({type: SET_AUTH_USER_INFO, payload})
        createNotification('success', 'Данные профиля успешно изменены')
      }
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({type: SET_AUTH_LOADING, payload: false})
    }
}
}

export const changePassword = (data) => async (dispatch, getState) => {
  
  dispatch({type: SET_AUTH_LOADING, payload: true})
  
  try {
    await dispatch(checkAndUpdateToken())
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/user/password`, {
            method: "PUT",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
          })
        if (response.ok) {
          createNotification('success', 'Пароль успешно изменен')
          return true
        } else {
          createNotification('error', 'Ошибка изменения пароля')
          return false
        }
    }
  } catch (ERR) {

  } finally {
    dispatch({type: SET_AUTH_LOADING, payload: false})
  }
}


export const checkSessionExist = (sessionId) => async (dispatch, getState) => {
    try {
      const response = await fetch(`${BACKEND_URL_PREFIX}/meeting/url/${sessionId}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const data = await response.json();
        return data.meeting_id
      } else {
        return null
      }
    } catch (ERR) {
      return null
    }
};

export const getConfUsers = (confId) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/users_to_meetings/meeting/${confId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      return data.users_to_meeting.user_info;
    }
  }
};