import * as types from "../actions/types";



const initialState = {
    contacts: null,
    conferences: null,
    confUsers: null,
    users: null,    
    loading: false
};


export default function lk (state = initialState, action) {
    switch (action.type) {
      case types.SET_USER_CONTACTS:
        return {
          ...state,
          contacts: action.payload
        };
      case types.SET_USER_CONFERENCES:
        return {
          ...state,
          conferences: action.payload
        };
      case types.SET_USERS_LIST:
        return {
          ...state,
          users: action.payload
        };
      case types.SET_CONF_USERS:
        return {
          ...state,
          confUsers: action.payload
        };
      case types.SET_LK_LOADING:
        return {
          ...state,
          loading: action.payload,
        };
      default:
        return state;
    }
  }